<template>
  <default-layout>
    <div class="back-link">
      <button v-button:back @click="$move('input')"></button>
    </div>
    <section>
      <form @submit="confirmCode">
        <p class="p-2 text-center">
          メールに記載されている確認コード（6桁の数字）を入力してください。
        </p>
        <div class="input-code" :class="{ wrong: codeError }">
          <input class="c-input__text" type="text" placeholder="000000" maxlength="6" v-model="code">
        </div>
        <div class="pt-3 pb-2 text-center">
          <button v-button:primary @click="confirmCode" :disabled="!filled"><span>送信</span></button>
        </div>
      </form>
    </section>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";
import {announce} from "@/calico-vue/service";

export default {
  data() {
    return {
      code: '',
      codeError: false
    }
  },
  computed: {
    filled() {
      return this.code.length == 6;
    }
  },
  methods: {
    shake () {
      this.code = '';
      this.codeError = true;
      setTimeout(() => { this.codeError = false; }, 360);
    },
    confirmCode(e) {
      e.preventDefault();
      Service.confirmCode(this.code)
          .then(() => {
            this.$move("/");
            announce.saved({title: "メールアドレスを変更しました。"});
          })
          .catch(e => {
            this.errors = e;
            this.shake();
          });
    }
  }
}
</script>

<style scoped lang="scss">
.wrong {
  animation: shake 0.12s linear;
  animation-iteration-count: 3;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-3%);
  }
  80% {
    transform: translateX(3%);
  }
  100% {
    transform: translateX(0);
  }
}
.input-code {
  width: 100%;
  max-width: 16em;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  background-color: #1676cc;
  border-radius: 10px;
  input {
    text-align: center;
    border-radius: 10px;
    border: 1px solid #cbcbcb;
    width: 100%;
    height: 48px;
    padding: 0 15px;
    font-size: 1.6rem;
    font-family: Noto Sans JP,sans-serif;
    color: #333;
  }
}
</style>
