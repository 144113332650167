<template>
  <default-layout>
    <div class="back-link">
      <button v-button:back @click="$move('/exam')"></button>
    </div>
    <section>
      <form v-form v-if="form">
        <table v-table:info v-cols="[50, 50]">
          <tbody>
            <tr>
              <th v-req>現在のパスワード</th>
              <td>
                <input-password v-model="form.currentPassword" :error="$error('currentPassword')"/>
              </td>
            </tr>
            <tr>
              <th v-req>新しいパスワード</th>
              <td>
                <input-password v-model="form.newPassword" :error="$error('newPassword')"/>
              </td>
            </tr>
            <tr>
              <th v-req>新しいパスワード(確認)</th>
              <td>
                <input-password v-model="form.newPasswordConfirm" :error="$error('newPasswordConfirm')"/>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center pt-2">
          <button v-button:save @click="save" />
        </div>
      </form>
    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';

export default {
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
      },
      error: null,
    }
  },
  methods: {
    save() {
      this.$processing(() =>
        Service.save(this.form).then(
          () => {
            this.$announce.success('パスワードを変更しました。');
            this.$move('/exam');
          },
          error => {
            this.error = error;
          }
        )
      );
    },
  }
};
</script>
