<template>
  <default-layout>
    <div class="back-link">
      <button v-button:back @click="$move('/')"></button>
    </div>
    <section>
      <form v-form>
        <p class="pt-2 pl-2 pb-1">
          新しいメールアドレスを入力してください。<br>
          入力されたメールアドレス宛に確認コードを送信します。
        </p>
        <div class="form-inner">
          <label class="font-weight-bold">現在のメールアドレス：</label>
          <div class="email">{{currEmail}}</div>
        </div>
        <div class="form-inner">
          <span class="font-weight-bold">新しいメールアドレス：</span>
          <input-text v-model="email" :error="$error('email')"/>
        </div>
        <div class="p-2">
          <button @click="send" v-button:primary>送信</button>
        </div>
      </form>
    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {authInfo} from '@/lib/auth/AuthInfo';

export default {
  data() {
    return {
      email: "",
      error: null,
      currEmail: authInfo.loginId
    }
  },
  methods: {
    send(e) {
      e.preventDefault();
      Service.sendConfirmationCode(this.email)
          .then(() => this.$move("confirm"))
          .catch(e => this.error = e);
    }
  }
};
</script>

<style scoped>
div.email {
  font-size: 1.8rem;
}
</style>
